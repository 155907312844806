<!-- 变压器 -->
<template>
  <div id="transformer">
    <!-- <div class="tree">
      <div>变配电站</div>
      <img src="../../assets/images/monitor/transformer.png" alt="" />
    </div> -->
    <div class="main">
      <div class="title">
        <img src="../../assets/images/LTicon.png" alt="" />
        <span>变压器</span>
        <!-- <img class="question" src="../../assets/images/monitor/question.svg" alt="" /> -->
      </div>
      <div class="content">
        <!-- <p>变压器名称：干变变压器</p> -->
        <ul class="info">
          <li>
            <div class="info-title">负荷</div>
            <div class="info-content" v-for="(item,index) in fuhe" :key="index">
              <div></div>
              <span style="white-space: nowrap;">{{ item.name }} : {{ formatNumber(item.value) }}</span>
            </div>
           
          </li>

          <li>
            <div class="info-title">总功率</div>
            <div class="info-content" v-for="(item,index) in gonglv" :key="index">
              <div></div>
              <span style="white-space: nowrap;">{{ item.name }}: {{ item.value }}</span>
            </div>
          
          </li>
          <li class="special">
            <div class="info-title">电流/电压</div>
            <div class="info-content" v-for="(item,index) in dianliu" :key="index">
              <div></div>
              <span style="white-space: nowrap;">{{ item.name }} : {{ item.value }}</span>
            </div>
           
          </li>
          <li>
            <div class="info-title">温度</div>
            <div class="info-content"  v-for="(item,index) in wendu" :key="index">
              <div></div>
              <span style="white-space: nowrap;">{{ item.name }} : {{ item.value }}</span>
            </div>
          </li>
          <li>
            <div class="info-title">噪声</div>
            <div class="info-content" >
              <div></div>
              <span style="white-space: nowrap;">{{ zaosheng.name }} : {{ zaosheng.value }}</span>
            </div>
           
          </li>
        </ul>
      </div>
      <div class="query-form">
        <el-form class="form" ref="form" :inline="true" :model="formData">
          <div class="flex-horizontal-between">
            <div class="">

              <el-form-item label="日期" class="query-date">
                <el-date-picker :append-to-body="false" placeholder="请选择日期" value-format="yyyy-MM-dd" type="date" v-model="formData.time"></el-date-picker>
              </el-form-item>
              <el-form-item label="回路名称">
              <el-select :popper-append-to-body="false" v-model="formData.deviceNumber" placeholder="请选择">
                <el-option v-for="(item, index) in loopList" :key="index" :label="item.loopName +' ('+ item.loopNumber+ ')'"
                  :value="item.deviceNumber"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类型">
            <el-select :popper-append-to-body="false" v-model="formData.type1" placeholder="请选择">
              <el-option v-for="item in selectSearch" :key="item.value" :label="item.name" :value="item.value"></el-option>
              
            </el-select>
          </el-form-item>
          <el-button type="primary" @click="searchList" icon="el-icon-search">搜索</el-button>
        </div>
          <!-- <el-button type="primary">下一日<i class="el-icon-arrow-right el-icon--right"></i></el-button> -->
            <el-form-item>
              <el-button type="warning" @click="operation = true">图表</el-button>
              <el-button type="primary" @click="operation = false">数据</el-button>
             
            </el-form-item>
          </div>
        </el-form>
        <el-table v-if="!operation" class="table" :data="tableData">
          <div slot="empty">
              <div v-if="tableData.length == 0">
                <div class="empty-data-view">
                  <div class="tableText">
                    {{  '暂无数据' }}
                  </div>
                </div>
              </div>
            </div>
          <el-table-column align="center" prop="nowTime" label="时间">
            <template slot-scope="scope">
              {{ scope.row.nowTime.substring(0, 5) }}
            </template>
          </el-table-column>
          <el-table-column align="center" prop="today" label="当日">
          </el-table-column>
          <el-table-column align="center" prop="yesterday" label="上日">
          </el-table-column>
        </el-table>
        <div v-show="operation" id="chart"></div>
      </div>
    </div>
  </div>
</template>
<script>
import * as echarts from "echarts";
import { nextTick } from "vue";
import { emissionsCarbonApi } from '@/api/emissionsCarbon.js'
export default {
  name: "",

  data() {
    return {
      formData: {
        time: "",
        deviceNumber:'',
        type1:'1',
      },
      loopList:[],
      stationNumber:'',
      operation: false,
      tableData:[],
      dianliu:[],
      fuhe:[],
      gonglv:[],
      wendu:{},
      zaosheng:{},
      selectSearch:[
        {
          name:'总有功功率',
          value:'1',
          unit:'Wh'
        },
        {
          name:'总无功功率',
          value:'2',
          unit:'var'
        },
        {
          name:'功率因数',
          value:'3',
          unit:''
        },
        {
          name:'A相电流',
          value:'4',
          unit:'A'
        },
        {
          name:'B相电流',
          value:'5',
          unit:'A'
        },
        {
          name:'C相电流',
          value:'6',
          unit:'A'
        },
        {
          name:'A相电压',
          value:'7',
          unit:'V'
        },
        {
          name:'B相电压',
          value:'8',
          unit:'V'
        },
        {
          name:'C相电压',
          value:'9',
          unit:'V'
        },

      ]



    };
  },
  created(){
    this.$store.commit('increment', '电力监测')
    this.$store.commit('selectChild', '变压器')
    this.$store.commit("selectChildren", "");
  },

  mounted() {
    this.stationNumber=sessionStorage.getItem('stationNumber')
    // this.stationNumber = 'ZD0279-01'
    emissionsCarbonApi.getLoopList({ stationNumber: this.stationNumber,position:2 }).then(res => {
      this.loopList = res.data
      this.formData.deviceNumber = res.data[0].deviceNumber
      this.getTransFormList()
      this.getTypeTransForm()
    })
    this.drawChart();
  },

  methods: {
    searchList(){
      this.getTransFormList()
    this.drawChart();
    this.getTypeTransForm()
    },
    switchTabs(index) {
      this.select = index;
    },
    handleCheck(checkedNodes, event) {
      this.checkedNodes = checkedNodes;
    },
    //获取头部
    getTypeTransForm(){
      let obj={
        deviceNumber:this.formData.deviceNumber,
        stationNumber:this.stationNumber
      }
      // let obj={
      //   deviceNumber: '001',
      //   stationNumber:this.stationNumber
      // }
      emissionsCarbonApi.getTypeTransForm(obj).then(res=>{
        let dianliu=[]
      for (const key in res.data.dianliu) {
        const obj = res.data.dianliu[key];
        obj.value = obj.value || '0';
        dianliu.push(obj);
      }
      this.dianliu=dianliu

        let fuhe=[]
      for (const key in res.data.fuhe) {
        const obj = res.data.fuhe[key];
        obj.value = obj.value || '0';
        fuhe.push(obj);
      }
      this.fuhe=fuhe
        let gonglv=[]
      for (const key in res.data.gonglv) {
        const obj = res.data.gonglv[key];
        obj.value = obj.value || '0';
        gonglv.push(obj);
      }
      this.gonglv=gonglv
        let wendu=[]
      for (const key in res.data.wendu) {
        const obj = res.data.wendu[key];
        obj.value = obj.value || '0';
        wendu.push(obj);
      }
      this.wendu=wendu
      
      // this.wendu=res.data.wendu
      // this.wendu.value=res.data.wendu.value?  this.wendu.value:'0'
      
      this.zaosheng=res.data.zaosheng
      this.zaosheng.value=res.data.zaosheng.value?  this.zaosheng.value:'0'
        
      })
    },
    getTransFormList(){
      let obj={
        deviceNumber:this.formData.deviceNumber,
        type1:this.formData.type1,
        endTime:this.formData.time,
        startTime:this.formData.time,
        stationNumber:this.stationNumber,
      }
      if(!this.formData.time){
        var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      }
      emissionsCarbonApi.getTransFormList(obj).then(res=>{
        this.tableData=res.data
      })
    },
    drawChart() {
      var unit = this.selectSearch.find(item=>item.value==this.formData.type1)
      console.log(unit);
      let obj={
        deviceNumber:this.formData.deviceNumber,
        type1:this.formData.type1,
        endTime:this.formData.time,
        startTime:this.formData.time,
        stationNumber:this.stationNumber,
      }
      if(!this.formData.time){
        var date = new Date();
        date.setDate(date.getDate());
        var yesterday = date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
        obj.startTime = yesterday
        obj.endTime = yesterday
      }
      emissionsCarbonApi.getTransFormList(obj).then(res=>{
        this.tableData=res.data
        let time=res.data.map(item=> item.nowTime.substring(0,5))
        let today=res.data.map(item=>item.today)
        let yesterday=res.data.map(item=>item.yesterday)
        var chart = echarts.init(document.getElementById("chart"));
        // 指定图表的配置相和数据
        var option = {
          tooltip: {},
          // title: {
          //   text: "2023-11-07   干变变压器   视在功率",
          //   textStyle: {
          //     color: "#e8f4ff",
          //     fontSize: 20,
          //     fontWeight: 700,
          //   },
          //   left: "center",
          // },
          legend: {
            right: "3%",
            textStyle: {
              color: "#aed6ff",
            },
          },
          color: ["#3376f7", "#14bf9b"],
          xAxis: {
            data: time,
            axisLabel: {
              textStyle: {
                color: "#aed6ff", // 设置字体颜色
                fontSize: 12,
              },
            },
          },
          yAxis: {
            name: "单位：" + unit.unit,
            nameTextStyle: {
              color: "#aed6ff",
            },
            // data: [0,1000,2000,3000,4000,5000,6000],
            axisLabel: {
              textStyle: {
                color: "#aed6ff", // 设置字体颜色
              },
            },
            splitLine: {
              show: false,
            },
          },
          series: [
            {
              name: "上日",
              type: "line",
              barWidth: 20,
              data: yesterday,
            },
            {
              name: "当日",
              type: "line",
              barWidth: 20,
              data: today,
            },
          ],
          
        };
        chart.setOption({
          dataZoom: [
              {
                  type: 'slider',    // 数据缩放类型为滑动条
                  start: 0,          // 开始位置（范围为 0 到 100）
                  end: 100            // 结束位置（范围为 0 到 100）
              },
              {
                  type: 'inside',    // 内置的数据缩放类型，鼠标滚轮缩放
                  start: 0,          // 开始位置（范围为 0 到 100）
                  end: 100            // 结束位置（范围为 0 到 100）
              }
                ]
            });
  
        // 使用刚指定的配置相和数据显示图表。
        chart.setOption(option);
      })
    },
    formatNumber(number) {
  // let rounded = Number(number).toFixed(2);
  // if (rounded.endsWith('.00')) {
  //   return rounded.slice(0, -3);
  // }
  return number;
}
  },
};
</script>
<style src="@/utils/style/public-style.css" scoped></style>
<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

#transformer {
  width: 100%;
  background: url("../../assets/images/rightBG.png") no-repeat;
  background-position: left left;
  background-size: cover;
  font-size: 16px;
  box-sizing: border-box;
  display: flex;
}

.tree {
  width: 240px;
}

.tree div {
  font-weight: 700;
  color: #d8e3ff;
  font-size: 14px;
  margin: 24px 38px 21px 21px;
}

.tree img {
  width: 48px;
  height: 48px;
  margin-left: 94px;
}

.main {
  width: 1376px;
  padding: 20px;
  box-sizing: border-box;
}

.title {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-bottom: 16px;
  display: flex;
  align-items: center;
}

.title img {
  width: 20px;
  height: 20px;
}

.title span {
  font-size: 16px;
  font-family: "Arial", sans-serif;
  color: #e8f4ff;
  margin-left: 10px;
}

.title .question {
  width: 15px;
  height: 15px;
  margin-left: 7px;
}

/* 内容 */
.content {
  width: 1336px;
  border-radius: 3px;
  background-color: #03173c;
  padding: 13px 20px 23px 21px;
  box-sizing: border-box;
}

.content p {
  height: 35px;
  background-color: rgba(255, 255, 255, 0);
  color: #d8e3ff;
  font-size: 14px;
  line-height: 35px;
  margin: 13px 0 0 23px;
}

.info {
  padding-left: 21px;
  margin-top: 7px;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
}

.info li {
  width: 220px;
  border-radius: 3px;
  background-color: rgba(5, 53, 125, 0.12);
  box-sizing: border-box;
  padding-bottom: 16px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.info .time {
  font-size: 12px;
  color: #aed6ff;
  margin-top: -8px;
}

.info-title {
  font-size: 14px;
  color: #d8e3ff;
  line-height: 35px;
  margin: 6px 0 17px 0;
}

.info-content:last-child {
  margin-bottom: 0;
}

.flex-horizontal-between {
  display: flex;
  justify-content: space-between;
}

.info .special {
  width: 335px;
  padding-left: 30px;
}

.info-content {
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 16px;
}

.info-content div {
  width: 10px;
  height: 10px;
  border-radius: 2px;
  background-color: #ffc74a;
  margin-right: 6px;
}

.info-content span {
  width: 134px;
  font-size: 12px;
  color: #aed6ff;
  display: block;
}

/* 查询表单 */
.query-form {
  width: 1336px;
  /* height: 518px; */
  border-radius: 3px;
  box-sizing: border-box;
  margin-top: 22px;
  padding: 23px 20px 0 22px;
  background-color: #03173c;
}

.form :deep() .el-icon-date {
  color: #aed6ff;
}

.form :deep().el-form-item__label {
  color: #aed6ff;
  font-size: 12px;
}

.form :deep() .el-form-item {
  margin: 0 8px 0 0;
}

/* input */
.form :deep() .el-input .el-input__inner {
  background-color: #041c4c;
  border: 1px solid #09295b;
  color: #aed6ff;
  width: 100%;
}

.form :deep() .el-date-editor.el-input {
  width: 157px;
  margin-right: 8px;
}

.form :deep() .el-select {
  /* width: 132px; */
  margin-left: 8px;
}

/* 表格 */
/* 设置表头的背景颜色 */
.table {
  margin-top: 31px;
}

.table :deep() thead th {
  background-color: rgb(9, 35, 78);
  color: #e8f4ff;
  font-size: 14px;
}

.table :deep() tbody tr {
  font-size: 14px;
  color: #aed6ff;
}

/* 设置表格内的背景颜色 */
::v-deep.el-table tbody tr:nth-child(odd) {
  background-color: #071734;
}

::v-deep.el-table tbody tr:nth-child(even) {
  background-color: #071734;
}

::v-deep.el-table {
  border: 2px solid #081f42 !important;
  position: relative;
}

::v-deep.el-table th {
  border: 1px solid #09234e !important;
}

::v-deep.el-table td {
  border: 1px solid #0d2247 !important;
}

.table ::v-deep .el-table__body tr:hover>td {
  background-color: #081f42 !important;
}

::v-deep .el-table::before {
  height: 0px;
}

::v-deep .el-table__fixed-right::before {
  height: 0px;
}

::v-deep .el-table__body tr.hover-row>td.el-table__cell {
  background-color: #081f42 !important;
}

#chart {
  width: 1336px;
  height: 460px;
  margin-top: 20px;
}
::v-deep .el-table__body-wrapper {
  height: 50vh;
  overflow: auto;
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar {
  width: 10px;
  /* 设置滚动条高度 */
  background-color: #09234E;
  /* 设置滚动条背景色 */
}

::v-deep .el-table__body-wrapper::-webkit-scrollbar-thumb {
  background-color: #003d74;
  /* 设置滚动条滑块颜色 */
  border-radius: 5px;
  /* 设置滚动条滑块的圆角 */
}
</style>
